// Generated by Framer (90417e1)

import { addFonts, addPropertyControls, ComponentViewportProvider, ControlType, cx, CycleVariantState, getFonts, getFontsFromSharedStyle, Link, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { Icon as Hero } from "https://framerusercontent.com/modules/hKjtTuWGYB451ckw6eTN/bbq95ZQZ142lE2fXscHd/Hero.js";
import * as sharedStyle1 from "../css/frwFdn91s";
import * as sharedStyle from "../css/XmahFg3TA";
const HeroFonts = getFonts(Hero);

const cycleOrder = ["KqySiImSL", "zhUwbkR6U"];

const serializationHash = "framer-tjALc"

const variantClassNames = {KqySiImSL: "framer-v-1o499h3", zhUwbkR6U: "framer-v-1uidomo"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {Default: "KqySiImSL", Small: "zhUwbkR6U"}

const getProps = ({color, height, icon, id, link, text, width, ...props}) => { return {...props, fyHS7O1bQ: link ?? props.fyHS7O1bQ, rcfnT1vd_: text ?? props.rcfnT1vd_ ?? "Read More", UBQ6k_NVL: color ?? props.UBQ6k_NVL ?? "var(--token-0c8fd507-faea-491a-8caf-ad6cd40c959b, rgb(33, 30, 28))", variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "KqySiImSL", ZMgecDAEH: icon ?? props.ZMgecDAEH ?? true} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;text?: string;link?: string;color?: string;icon?: boolean; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, rcfnT1vd_, fyHS7O1bQ, UBQ6k_NVL, ZMgecDAEH, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "KqySiImSL", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = [sharedStyle.className, sharedStyle1.className]

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Link href={fyHS7O1bQ}><motion.a {...restProps} {...gestureHandlers} className={`${cx(serializationHash, ...sharedStyleClassNames, "framer-1o499h3", className, classNames)} framer-188ougx`} data-framer-name={"Default"} layoutDependency={layoutDependency} layoutId={"KqySiImSL"} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({zhUwbkR6U: {"data-framer-name": "Small"}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p className={"framer-styles-preset-iqff21"} data-styles-preset={"XmahFg3TA"} style={{"--framer-text-color": "var(--extracted-r6o4lv, var(--variable-reference-UBQ6k_NVL-wgln9sRdu))"}}>Read More</motion.p></React.Fragment>} className={"framer-1nnw00a"} data-framer-name={"Text"} fonts={["Inter"]} layoutDependency={layoutDependency} layoutId={"NxDLFQNiO"} style={{"--extracted-r6o4lv": "var(--variable-reference-UBQ6k_NVL-wgln9sRdu)", "--variable-reference-UBQ6k_NVL-wgln9sRdu": UBQ6k_NVL}} text={rcfnT1vd_} verticalAlignment={"center"} withExternalLayout {...addPropertyOverrides({zhUwbkR6U: {children: <React.Fragment><motion.p className={"framer-styles-preset-q8avul"} data-styles-preset={"frwFdn91s"} style={{"--framer-text-color": "var(--extracted-r6o4lv, var(--variable-reference-UBQ6k_NVL-wgln9sRdu))"}}>Read More</motion.p></React.Fragment>}}, baseVariant, gestureVariant)}/>{ZMgecDAEH && (<ComponentViewportProvider ><motion.div className={"framer-vylv06-container"} layoutDependency={layoutDependency} layoutId={"GfmVdO86I-container"}><Hero color={UBQ6k_NVL} height={"100%"} iconSearch={"Home"} iconSelection={"ArrowRight"} id={"GfmVdO86I"} layoutId={"GfmVdO86I"} mirrored={false} selectByList style={{height: "100%", width: "100%"}} width={"100%"}/></motion.div></ComponentViewportProvider>)}</motion.a></Link></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-tjALc.framer-188ougx, .framer-tjALc .framer-188ougx { display: block; }", ".framer-tjALc.framer-1o499h3 { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: visible; padding: 2px 0px 2px 0px; position: relative; text-decoration: none; width: min-content; }", ".framer-tjALc .framer-1nnw00a { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", ".framer-tjALc .framer-vylv06-container { flex: none; height: 16px; position: relative; width: 16px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-tjALc.framer-1o499h3 { gap: 0px; } .framer-tjALc.framer-1o499h3 > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-tjALc.framer-1o499h3 > :first-child { margin-left: 0px; } .framer-tjALc.framer-1o499h3 > :last-child { margin-right: 0px; } }", ...sharedStyle.css, ...sharedStyle1.css]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 28
 * @framerIntrinsicWidth 101
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"zhUwbkR6U":{"layout":["auto","auto"]}}}
 * @framerVariables {"rcfnT1vd_":"text","fyHS7O1bQ":"link","UBQ6k_NVL":"color","ZMgecDAEH":"icon"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const Framerwgln9sRdu: React.ComponentType<Props> = withCSS(Component, css, "framer-tjALc") as typeof Component;
export default Framerwgln9sRdu;

Framerwgln9sRdu.displayName = "Button Link";

Framerwgln9sRdu.defaultProps = {height: 28, width: 101};

addPropertyControls(Framerwgln9sRdu, {variant: {options: ["KqySiImSL", "zhUwbkR6U"], optionTitles: ["Default", "Small"], title: "Variant", type: ControlType.Enum}, rcfnT1vd_: {defaultValue: "Read More", displayTextArea: false, title: "Text", type: ControlType.String}, fyHS7O1bQ: {title: "Link", type: ControlType.Link}, UBQ6k_NVL: {defaultValue: "var(--token-0c8fd507-faea-491a-8caf-ad6cd40c959b, rgb(33, 30, 28))", title: "Color", type: ControlType.Color}, ZMgecDAEH: {defaultValue: true, title: "Icon", type: ControlType.Boolean}} as any)

addFonts(Framerwgln9sRdu, [{explicitInter: true, fonts: [{family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F", url: "https://framerusercontent.com/assets/5vvr9Vy74if2I6bQbJvbw7SY1pQ.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116", url: "https://framerusercontent.com/assets/EOr0mi4hNtlgWNn9if640EZzXCo.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+1F00-1FFF", url: "https://framerusercontent.com/assets/Y9k9QrlZAqio88Klkmbd8VoMQc.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0370-03FF", url: "https://framerusercontent.com/assets/OYrD2tBIBPvoJXiIHnLoOXnY9M.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF", url: "https://framerusercontent.com/assets/JeYwfuaPfZHQhEG8U5gtPDZ7WQ.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD", url: "https://framerusercontent.com/assets/vQyevYAyHtARFwPqUzQGpnDs.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB", url: "https://framerusercontent.com/assets/b6Y37FthZeALduNqHicBT6FutY.woff2", weight: "400"}]}, ...HeroFonts, ...getFontsFromSharedStyle(sharedStyle.fonts), ...getFontsFromSharedStyle(sharedStyle1.fonts)], {supportsExplicitInterCodegen: true})